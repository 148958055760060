import { AFASApplication } from '@afas/podium/podium';

import { JsonPodiumApplicationConfig } from '../json-podium-application';

export function ifConfigMiddleware<R extends AFASApplication | JsonPodiumApplicationConfig>(
  middleware: (config: JsonPodiumApplicationConfig) => Promise<R>
): (appOrConfig: AFASApplication | JsonPodiumApplicationConfig) => Promise<R> {
  return async (appOrConfig): Promise<R> => {
    if (isConfig(appOrConfig)) {
      return middleware(appOrConfig);
    }

    return appOrConfig as R;
  };
}

function isConfig(appOrConfig: AFASApplication | JsonPodiumApplicationConfig): appOrConfig is JsonPodiumApplicationConfig {
  return 'appAssetsBaseUrl' in appOrConfig;
}
